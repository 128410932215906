import React from "react";
import { useRef } from "react";

const Button = ({ timeout, energy, coin, setCoin, setEnergy, level }) => {
  const ref = useRef();
  async function hadnleClick(e) {
    if (energy <= 0) {
      setTimeout(() => {
        setTimeout(timeout);
      }, 1000);
      return alert("No Energy");
    }
    clearTimeout(timeout);
    e.preventDefault();
    const energys = energy - 1;
    setEnergy(energys);

    const element = document.createElement("span");
    element.innerText = `${level}`;
    const rand = e.clientX + 3;
    const rand2 = e.clientY;
    element.classList = `animation_fade absolute text-white`;
    element.style.left = rand + "px";
    element.style.top = rand2 + "px";
    ref.current.append(element);
    const img = document.createElement("img");
    img.src = "/img/logo.png";
    img.classList = `animation_fade absolute w-[40px]`;
    img.style.left = rand + (level == 10 ? 30 : 10) + "px";
    img.style.top = rand2 + "px";
    ref.current.append(img);

    const coins = coin + level;
    setCoin(coins);
    setTimeout(() => {
      setTimeout(timeout);
    }, 1000);
  }
  return (
    <div ref={ref} className="mt-[50px]  ">
      <img
        onClick={hadnleClick}
        className="w-[245px] h-[244px] active:scale-[.97] duration-75"
        src="/img/button.png"
        alt=""
      />
    </div>
  );
};

export default Button;
