import React, { useContext } from "react";
import { useState } from "react";
import { userContext } from "../context/userContext";
import ExcludeIcon from "../icons/ExcludeIcon";
import { httpReauest } from "../utils/httpRequest";

const DailyReward = ({ setOpen, id, setId, setCoin, setEnergy }) => {
  const { data, setData } = useContext(userContext);

  const daysReward = [
    { day: 1, point: 1, active: false, bg: "#84CB69" },
    { day: 2, point: 1, active: true, bg: "#FFFFFF" },
    { day: 3, point: 1, active: true, bg: "#FFFFFF" },
    { day: 4, point: 1, active: true, bg: "#FFFFFF" },
    { day: 5, point: 1, active: true, bg: "#FFFFFF" },
    { day: 6, point: 1, active: true, bg: "#FFFFFF" },
    { day: 7, point: 1, active: true, bg: "#FFFFFF" },
    { day: 8, point: 1, active: true, bg: "#FFFFFF" },
    { day: 9, point: 1, active: true, bg: "#F9D838" },
    { day: 10, point: 1, active: true, bg: "#FFFFFF" },
    { day: 11, point: 1, active: true, bg: "#FFFFFF" },
    { day: 12, point: 1, active: true, bg: "#FFFFFF" },
    { day: 13, point: 1, active: true, bg: "#B8B9B8" },
    { day: 14, point: 1, active: true, bg: "#FFFFFF" },
    { day: 15, point: 1, active: true, bg: "#FFFFFF" },
    { day: 16, point: 1, active: true, bg: "#FFFFFF" },
  ];
  function dailyReward(day) {
    const baseAmount = 500; // مقدار پایه
    let total = baseAmount; // شروع با مقدار پایه

    for (let i = 1; i < day; i++) {
      total += baseAmount + i * 2000; // افزایش تدریجی
    }

    return total;
  }
  const [step, setStep] = useState(false);
  async function handleClaim() {
    const id = data?.user?.telegramId;
    const response = await httpReauest(
      "POST",
      "/user/claim",
      { telegramId: id },
      {}
    );
    const getUser = await httpReauest("GET", `/user/${id}`, {}, {});
    console.log(getUser);
    setData(getUser.data.data);
    setCoin(getUser.data?.data?.user?.balance);
    setEnergy(getUser?.data?.data?.user?.energy);
    setId(id);
  }
  function handleClick() {
    setStep(true);
    handleClaim();
  }
  return (
    <div className="flex flex-col items-center w-full p-2">
      {step ? (
        <>
          <div className="w-full flex justify-end items-center px-5 mt-4">
            <button onClick={() => setOpen(false)} className=" ">
              <ExcludeIcon />
            </button>
          </div>
          <img className="w-[119px] h-[119px]" src="/img/button.png" alt="" />
          <h3 className="font-[700] text-[24px] mt-2">Narad Club</h3>
          <span className="flex justify-center items-center mt-5">
            <img className="w-[29px] h-[29px]" src="/img/logo.png" alt="" />
            <div className="flex flex-col items-center">
              <h3 className="font-[700] text-[24px]">1 NARAD</h3>
              <p className="text-[10px] font-[500] text-[#F4B92E]">
                Daily Rewards
              </p>
            </div>
          </span>
          <span className="bg-[#96721E] w-[90%] h-[70px] rounded-[15px] flex justify-center mx-4 mt-4 mb-6">
            <button
              onClick={() => setOpen(false)}
              className="text-[16px] font-[600] text-center"
            >
              Come back tomorrow
            </button>
          </span>
        </>
      ) : (
        <>
          <h2 className="text-[#F4B92E] text-[24px] font-[600] mt-3">
            Daily reward
          </h2>
          <p className="text-14px font-[500] text-center mx-4">
            Accrue coins for logging into the game daily without skipping
          </p>
          <div className="grid grid-cols-4 gap-1 mt-4">
            {daysReward.map((e) => (
              <span
                style={{
                  backgroundColor:
                    e.day < data?.user?.day ? "#84CB69" : "#FFFFFF",
                }}
                className={`rounded-[10px] w-[90px] h-[90px] p-1 text-[#000000] flex flex-col items-center ${
                  e.day <= data?.user?.day ? "blur-none" : "blur-[2px]"
                }`}
              >
                <p className="text-[14px] font-[700]">Day{e.day}</p>
                <img className="w-[34px]" src="/img/button.png" alt="" />
                <p className=" text-[14px] font-[500]">
                  {dailyReward(e.day)}NARAD
                </p>
              </span>
            ))}
          </div>
          <button
            onClick={handleClick}
            className="bg-[#96721E] w-[90%] h-[70px] rounded-[15px] items-center flex justify-center mx-4 mt-4 mb-6 text-[16px] font-[600] text-center"
          >
            Claim
          </button>
        </>
      )}
    </div>
  );
};

export default DailyReward;
