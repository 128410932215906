import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ProfileIcon from "../icons/ProfileIcon";

const FriendsList = ({ friends }) => {
  const [friendsList, setFriendsList] = useState([]);
  useEffect(() => {
    setFriendsList(friends);
    console.log(friendsList);
  }, []);
  return (
    <div className="w-full mt-[20px]">
      <h3>List of your friends ( {friendsList.length} )</h3>

      {friendsList?.map((e) => (
        <span className="w-full mt-2 bg-[#000] flex justify-between items-center py-2 px-2 shadow-md shadow-[#000000]/30  rounded-lg">
          <span className="flex  justify-start items-center gap-2">
            <ProfileIcon />
            <span className="flex flex-col items-start">
              <p className="test-[16px] font-[600]">NARAD Team</p>
              <p className="text-[12px] font-[400]">{e.username}</p>
            </span>
          </span>
          <p className="text-[16px] text-[#FFA100] font-[600]">+150 NARAD</p>
        </span>
      ))}
    </div>
  );
};

export default FriendsList;
