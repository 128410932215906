import React from "react";

const FlagIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.58325 11.9167V3.25C4.58325 3.0143 4.58325 2.89645 4.65648 2.82322C4.7297 2.75 4.84755 2.75 5.08325 2.75H8.76241C8.96195 2.75 9.06172 2.75 9.15186 2.78643C9.242 2.82286 9.31375 2.89219 9.45725 3.03084L10.7734 4.3025C10.9169 4.44114 10.9887 4.51047 11.0788 4.5469C11.1689 4.58333 11.2687 4.58333 11.4683 4.58333H17.0682C17.3109 4.58333 17.4323 4.58333 17.4749 4.66151C17.5176 4.73968 17.4519 4.84171 17.3204 5.04576L14.8409 8.89595C14.7563 9.0274 14.7139 9.09313 14.7139 9.16667C14.7139 9.2402 14.7563 9.30593 14.8409 9.43739L17.3204 13.2876C17.4519 13.4916 17.5176 13.5936 17.4749 13.6718C17.4323 13.75 17.3109 13.75 17.0682 13.75H11.4683C11.2687 13.75 11.1689 13.75 11.0788 13.7136C10.9887 13.6771 10.9169 13.6078 10.7734 13.4692L9.45725 12.1975C9.31375 12.0589 9.242 11.9895 9.15186 11.9531C9.06172 11.9167 8.96195 11.9167 8.76241 11.9167H4.58325ZM4.58325 11.9167V17.4167"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
      <path
        d="M4.58325 3.25V11.4167C4.58325 11.6524 4.58325 11.7702 4.65648 11.8434C4.7297 11.9167 4.84755 11.9167 5.08325 11.9167H8.76241C8.96195 11.9167 9.06172 11.9167 9.15186 11.9531C9.242 11.9895 9.31375 12.0589 9.45725 12.1975L9.45725 12.1975L10.7734 13.4692C10.9169 13.6078 10.9887 13.6771 11.0788 13.7136C11.1689 13.75 11.2687 13.75 11.4683 13.75H17.0682C17.3109 13.75 17.4323 13.75 17.4749 13.6718C17.5176 13.5936 17.4519 13.4916 17.3204 13.2876L14.8409 9.43739C14.7563 9.30593 14.7139 9.2402 14.7139 9.16667C14.7139 9.09313 14.7563 9.0274 14.8409 8.89595L17.3204 5.04576C17.4519 4.84171 17.5176 4.73968 17.4749 4.66151C17.4323 4.58333 17.3109 4.58333 17.0682 4.58333H11.4683C11.2687 4.58333 11.1689 4.58333 11.0788 4.5469C10.9887 4.51047 10.9169 4.44114 10.7734 4.3025L9.45725 3.03084C9.31375 2.89219 9.242 2.82286 9.15186 2.78643C9.06172 2.75 8.96195 2.75 8.76241 2.75H5.08325C4.84755 2.75 4.7297 2.75 4.65648 2.82322C4.58325 2.89645 4.58325 3.0143 4.58325 3.25Z"
        fill="white"
      />
    </svg>
  );
};

export default FlagIcon;
