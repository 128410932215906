import { useEffect } from "react";
import { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./app.css";
import Navbar from "./components/Navbar";
import FriendsPage from "./pages/FriendsPage";
import Home from "./pages/Home";
import MinePage from "./pages/MinePage";
import QuestPage from "./pages/QuestPage";
import WalletPage from "./pages/WalletPage";
import { httpReauest } from "./utils/httpRequest";
import { userContext } from "./context/userContext";
const App = () => {
  const [energy, setEnergy] = useState(1);
  const [coin, setCoin] = useState(1);
  const [data, setData] = useState([]);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);

  async function sendData() {
    if (data?.user) {
      console.log(coin);
      console.log(energy);
      console.log(id);
      const response = await httpReauest(
        "POST",
        "/user/addbalance",
        { energy: energy, balance: coin, telegramId: id },
        {}
      );
    }
  }

  return (
    <>
      <BrowserRouter>
        <userContext.Provider value={{ data, setData }}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  loading={loading}
                  setLoading={setLoading}
                  energy={energy}
                  setEnergy={setEnergy}
                  coin={coin}
                  setCoin={setCoin}
                  sendData={sendData}
                  id={id}
                  setId={setId}
                />
              }
            />
            <Route
              path="/mine"
              element={
                <MinePage
                  coin={coin}
                  setCoin={setCoin}
                  energy={energy}
                  setEnergy={setEnergy}
                  id={id}
                />
              }
            />

            <Route
              path="/freinds"
              element={
                <FriendsPage
                  coin={coin}
                  setCoin={setCoin}
                  energy={energy}
                  setEnergy={setEnergy}
                  id={id}
                />
              }
            />
            <Route
              path="/quest"
              element={
                <QuestPage
                  setId={setId}
                  coin={coin}
                  setCoin={setCoin}
                  energy={energy}
                  setEnergy={setEnergy}
                  id={id}
                />
              }
            />
            <Route
              path="/wallet"
              element={
                <WalletPage
                  coin={coin}
                  setCoin={setCoin}
                  energy={energy}
                  setEnergy={setEnergy}
                  id={id}
                />
              }
            />
          </Routes>
        </userContext.Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
