import React from "react";

const SendIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.875 3.125V2.125H22.875V3.125H21.875ZM12.1654 14.2488C11.7749 14.6393 11.1417 14.6393 10.7512 14.2488C10.3607 13.8583 10.3607 13.2251 10.7512 12.8346L12.1654 14.2488ZM20.875 11.4583V3.125H22.875V11.4583H20.875ZM21.875 4.125H13.5417V2.125H21.875V4.125ZM22.5821 3.83211L12.1654 14.2488L10.7512 12.8346L21.1679 2.41789L22.5821 3.83211Z"
        fill="white"
      />
      <path
        d="M20.8333 15.4583V15.4583C20.8333 18.3348 18.5014 20.6667 15.6249 20.6667H12.4999C7.89755 20.6667 4.16659 16.9357 4.16659 12.3333V9.20833C4.16659 6.33185 6.49844 4 9.37492 4V4"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default SendIcon;
