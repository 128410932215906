import React, { useContext } from "react";
import { useEffect } from "react";
import Balance from "../components/Balance";
import Navbar from "../components/Navbar";
import { userContext } from "../context/userContext";
import BellIcon from "../icons/BellIcon";
import MoreIcon from "../icons/MoreIcon";
import ProfileIcon from "../icons/ProfileIcon";
import SendIcon from "../icons/SendIcon";
import Settingicon from "../icons/Settingicon";

const WalletPage = ({ coin }) => {
  const { data, setData } = useContext(userContext);
  return (
    <div className="flex flex-col items-center w-full mt-[30px] px-[30px]">
      <div className={`flex justify-between items-center w-full `}>
        <div className="flex justify-center items-center gap-1">
          <ProfileIcon />
          <p className="text-[12px] font-[600]">{data?.user?.username}</p>
        </div>
        <div className="flex items-center"></div>
      </div>
      <Balance coin={coin} />
      <div className="grid grid-cols-4 w-full text-[14px] font-[600] gap-[5px] items-center mt-3 ">
        <span className="col-span-1 flex gap-1 justify-start items-center bg-[#000] border border-white/60 rounded-[7px] py-2 px-1">
          <SendIcon />
          <p>Send</p>
        </span>
        <span className="col-span-2 bg-[#000] border border-white/60 rounded-[7px] text-center h-full flex items-center justify-center">
          Receive
        </span>
        <span className="col-span-1 bg-[#000] border border-white/60 rounded-[7px] text-center h-full flex items-center justify-center">
          Buy
        </span>
      </div>
      <div className="bg-[#96721E] flex flex-col items-center rounded-t-[50px] border-t-[4px] h-[65%] border-[#FFFFFF] absolute bottom-0 left-0 right-0">
        <div className="grid grid-cols-2 w-full justify-items-center mt-6 text-[15px] font-[600]">
          <span className="border-b-[4px] border-[#00172C] px-4">Token</span>
          <span className="px-4">Collectibles</span>
        </div>
        <img
          className="w-[245px] h-[245px] mt-6"
          src="/img/button.png"
          alt=""
        />
        <h3 className="text-[36px] font-[700]">COMING SOON</h3>
        <Navbar />
      </div>
    </div>
  );
};

export default WalletPage;
