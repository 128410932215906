import React from "react";
import { useLocation } from "react-router-dom";

const FriendsIcon = () => {
  const location = useLocation();
  console.log(location.pathname)
  return (
    <svg
      width="38"
      height="24"
      viewBox="0 0 38 24"
      fill={location.pathname==='/freinds' ? 'white':"#565656"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity='1'>
        <path
          d="M8.80195 11.0111C10.21 11.0111 11.3515 9.86967 11.3515 8.46162C11.3515 7.05356 10.21 5.91211 8.80195 5.91211C7.39389 5.91211 6.25244 7.05356 6.25244 8.46162C6.25244 9.86967 7.39389 11.0111 8.80195 11.0111Z"
          fill={location.pathname==='/freinds' ? 'white':"#565656"}
        />
        <path
          d="M12.2055 12.7958C11.5439 13.2938 11.0072 13.9389 10.6377 14.6801C10.2683 15.4212 10.0762 16.2381 10.0767 17.0662V17.3849H4.65901C4.13636 17.3849 3.70294 16.9515 3.70294 16.4288V15.7915C3.70294 13.8538 5.27089 12.2859 7.20851 12.2859H10.3954C11.0583 12.2859 11.6829 12.4771 12.2055 12.7958Z"
          fill={location.pathname==='/freinds' ? 'white':"#565656"}
        />
        <path
          d="M29.198 11.0111C30.6061 11.0111 31.7475 9.86967 31.7475 8.46162C31.7475 7.05356 30.6061 5.91211 29.198 5.91211C27.79 5.91211 26.6485 7.05356 26.6485 8.46162C26.6485 9.86967 27.79 11.0111 29.198 11.0111Z"
          fill={location.pathname==='/freinds' ? 'white':"#565656"}
        />
        <path
          d="M34.297 15.7915V16.4288C34.297 16.9515 33.8636 17.3849 33.341 17.3849H27.9233V17.0662C27.9233 15.3198 27.0819 13.7646 25.7944 12.7958C26.3171 12.4771 26.9417 12.2859 27.6046 12.2859H30.7915C32.7291 12.2859 34.297 13.8538 34.297 15.7915Z"
          fill={location.pathname==='/freinds' ? 'white':"#565656"}
        />
        <path
          d="M19 11.6485C21.1121 11.6485 22.8242 9.93634 22.8242 7.82426C22.8242 5.71218 21.1121 4 19 4C16.8879 4 15.1757 5.71218 15.1757 7.82426C15.1757 9.93634 16.8879 11.6485 19 11.6485Z"
          fill={location.pathname==='/freinds' ? 'white':"#565656"}
        />
        <path
          d="M22.5055 13.5607H15.4944C14.5649 13.5613 13.6736 13.9309 13.0163 14.5882C12.3591 15.2454 11.9895 16.1367 11.9888 17.0662V18.9784C11.9888 19.5061 12.4171 19.9344 12.9449 19.9344H25.055C25.3086 19.9344 25.5518 19.8337 25.7311 19.6544C25.9104 19.4751 26.0111 19.2319 26.0111 18.9784V17.0662C26.0104 16.1367 25.6409 15.2454 24.9836 14.5882C24.3263 13.9309 23.4351 13.5613 22.5055 13.5607Z"
          fill={location.pathname==='/freinds' ? 'white':"#565656"}
        />
      </g>
    </svg>
  );
};

export default FriendsIcon;
