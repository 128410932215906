import React from "react";

const YoutubeIcon = () => {
  return (
    <svg
      width="31"
      height="20"
      viewBox="0 0 31 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.3694 3.14446C30.0131 1.91979 28.9676 0.953478 27.6402 0.623761C25.2146 0.010002 15.5107 1.08428e-05 15.5107 1.08428e-05C15.5107 1.08428e-05 5.80839 -0.00998088 3.38126 0.576659C2.08018 0.903521 1.00524 1.89125 0.645896 3.11448C0.00619817 5.34971 2.26938e-06 9.98573 2.26938e-06 9.98573C2.26938e-06 9.98573 -0.00619332 14.6446 0.628857 16.857C0.985105 18.0802 2.03062 19.0465 3.35958 19.3762C5.80994 19.99 15.4875 20 15.4875 20C15.4875 20 25.1914 20.01 27.617 19.4248C28.9428 19.0965 29.993 18.1316 30.3539 16.9084C30.9951 14.6746 30.9998 10.04 30.9998 10.04C30.9998 10.04 31.0308 5.37968 30.3694 3.14446ZM12.4067 14.2806L12.4145 5.71653L20.4796 10.0057L12.4067 14.2806Z"
        fill="#FF0000"
      />
    </svg>
  );
};

export default YoutubeIcon;
