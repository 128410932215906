import React from "react";

const SupriseIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 0C6.01664 0 4.56659 0.439867 3.33323 1.26398C2.09986 2.08809 1.13856 3.25943 0.570907 4.62987C0.00324963 6.00032 -0.145275 7.50832 0.144114 8.96318C0.433503 10.418 1.14781 11.7544 2.1967 12.8033C3.2456 13.8522 4.58197 14.5665 6.03682 14.8559C7.49168 15.1453 8.99968 14.9967 10.3701 14.4291C11.7406 13.8614 12.9119 12.9001 13.736 11.6668C14.5601 10.4334 15 8.98336 15 7.5C14.9979 5.51152 14.207 3.60509 12.801 2.19902C11.3949 0.79295 9.48848 0.00209987 7.5 0ZM7.21154 3.46154C7.3827 3.46154 7.55001 3.51229 7.69232 3.60738C7.83463 3.70247 7.94555 3.83763 8.01105 3.99575C8.07655 4.15388 8.09369 4.32788 8.0603 4.49575C8.02691 4.66362 7.94449 4.81782 7.82346 4.93884C7.70243 5.05987 7.54824 5.14229 7.38037 5.17568C7.2125 5.20907 7.0385 5.19193 6.88037 5.12643C6.72224 5.06093 6.58709 4.95002 6.492 4.8077C6.39691 4.66539 6.34616 4.49808 6.34616 4.32692C6.34616 4.09741 6.43733 3.87729 6.59962 3.715C6.76191 3.55271 6.98203 3.46154 7.21154 3.46154ZM8.07692 11.5385C7.77091 11.5385 7.47742 11.4169 7.26103 11.2005C7.04464 10.9841 6.92308 10.6906 6.92308 10.3846V7.5C6.77007 7.5 6.62333 7.43922 6.51513 7.33102C6.40694 7.22283 6.34616 7.07609 6.34616 6.92308C6.34616 6.77007 6.40694 6.62332 6.51513 6.51513C6.62333 6.40694 6.77007 6.34615 6.92308 6.34615C7.2291 6.34615 7.52258 6.46772 7.73897 6.68411C7.95536 6.90049 8.07692 7.19398 8.07692 7.5V10.3846C8.22993 10.3846 8.37668 10.4454 8.48487 10.5536C8.59307 10.6618 8.65385 10.8085 8.65385 10.9615C8.65385 11.1145 8.59307 11.2613 8.48487 11.3695C8.37668 11.4777 8.22993 11.5385 8.07692 11.5385Z"
        fill="white"
        fill-opacity="0.2"
      />
    </svg>
  );
};

export default SupriseIcon;
