import React from "react";

const WalletIcon = ({location}) => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 16C2.9 16 1.95833 15.6083 1.175 14.825C0.391667 14.0417 0 13.1 0 12V4C0 2.9 0.391667 1.95833 1.175 1.175C1.95833 0.391667 2.9 0 4 0H16C17.1 0 18.0417 0.391667 18.825 1.175C19.6083 1.95833 20 2.9 20 4V12C20 13.1 19.6083 14.0417 18.825 14.825C18.0417 15.6083 17.1 16 16 16H4ZM4 4H16C16.3667 4 16.7167 4.04167 17.05 4.125C17.3833 4.20833 17.7 4.34167 18 4.525V4C18 3.45 17.8042 2.97917 17.4125 2.5875C17.0208 2.19583 16.55 2 16 2H4C3.45 2 2.97917 2.19583 2.5875 2.5875C2.19583 2.97917 2 3.45 2 4V4.525C2.3 4.34167 2.61667 4.20833 2.95 4.125C3.28333 4.04167 3.63333 4 4 4ZM2.15 7.25L13.275 9.95C13.425 9.98333 13.575 9.98333 13.725 9.95C13.875 9.91667 14.0167 9.85 14.15 9.75L17.625 6.85C17.4417 6.6 17.2083 6.39583 16.925 6.2375C16.6417 6.07917 16.3333 6 16 6H4C3.56667 6 3.1875 6.1125 2.8625 6.3375C2.5375 6.5625 2.3 6.86667 2.15 7.25Z"
        fill={location.pathname==='/wallet' ? 'white':"#565656"}
      />
    </svg>
  );
};

export default WalletIcon;
