import React from "react";

const HomeIcon = ({location}) => {
  
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 18.2778H6.25V11.6111H13.75V18.2778H17.5V8.27778L10 3.27778L2.5 8.27778V18.2778ZM0 20.5V7.16667L10 0.5L20 7.16667V20.5H11.25V13.8333H8.75V20.5H0Z"
        fill={location.pathname==='/' ? 'white':"#565656"}
      />
    </svg>
  );
};

export default HomeIcon;
