import React from "react";

const QuestIcon = ({location}) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="1">
        <path
          d="M12 22.5037C10.6167 22.5037 9.31667 22.2412 8.1 21.7162C6.88333 21.1912 5.825 20.4787 4.925 19.5787C4.025 18.6787 3.3125 17.6203 2.7875 16.4037C2.2625 15.187 2 13.887 2 12.5037C2 11.1203 2.2625 9.82033 2.7875 8.60366C3.3125 7.387 4.025 6.32866 4.925 5.42866C5.825 4.52866 6.88333 3.81616 8.1 3.29116C9.31667 2.76616 10.6167 2.50366 12 2.50366C13.0833 2.50366 14.1083 2.662 15.075 2.97866C16.0417 3.29533 16.9333 3.737 17.75 4.30366L16.3 5.77866C15.6667 5.37866 14.9917 5.06616 14.275 4.84116C13.5583 4.61616 12.8 4.50366 12 4.50366C9.78333 4.50366 7.89583 5.28283 6.3375 6.84116C4.77917 8.3995 4 10.287 4 12.5037C4 14.7203 4.77917 16.6078 6.3375 18.1662C7.89583 19.7245 9.78333 20.5037 12 20.5037C14.2167 20.5037 16.1042 19.7245 17.6625 18.1662C19.2208 16.6078 20 14.7203 20 12.5037C20 12.2037 19.9833 11.9037 19.95 11.6037C19.9167 11.3037 19.8667 11.012 19.8 10.7287L21.425 9.10366C21.6083 9.637 21.75 10.187 21.85 10.7537C21.95 11.3203 22 11.9037 22 12.5037C22 13.887 21.7375 15.187 21.2125 16.4037C20.6875 17.6203 19.975 18.6787 19.075 19.5787C18.175 20.4787 17.1167 21.1912 15.9 21.7162C14.6833 22.2412 13.3833 22.5037 12 22.5037ZM10.6 17.1037L6.35 12.8537L7.75 11.4537L10.6 14.3037L20.6 4.27866L22 5.67866L10.6 17.1037Z"
          fill={location.pathname==='/quest' ? 'white':"#565656"}
        />
      </g>
    </svg>
  );
};

export default QuestIcon;
