import React from "react";

const TelegramIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1883 0.0596629C13.0676 -0.233429 13.9041 0.603084 13.611 1.48237L9.80552 12.8989C9.4483 13.9705 7.91954 13.9042 7.6261 12.8365C7.35987 11.868 7.01828 10.6853 6.68109 9.68448C6.51221 9.18324 6.34749 8.73682 6.19671 8.38928C6.12124 8.21539 6.05252 8.07379 5.9919 7.96592C5.96181 7.91227 5.93583 7.87083 5.91429 7.84001C5.90366 7.82482 5.895 7.81341 5.88831 7.80525C5.88505 7.80121 5.88246 7.79822 5.8806 7.79609L5.87847 7.79378L5.8779 7.79322C5.87762 7.79294 5.87374 7.78911 5.86548 7.78237C5.85727 7.77567 5.84585 7.76701 5.83067 7.75638C5.79985 7.7349 5.7584 7.70892 5.70481 7.67878C5.59689 7.61815 5.45528 7.54943 5.2814 7.47396C4.93385 7.32319 4.48744 7.15847 3.98622 6.98959C2.98541 6.65239 1.80268 6.31086 0.834168 6.04463C-0.233564 5.75113 -0.299823 4.22238 0.77179 3.86516L12.1883 0.0596629ZM8.73847 12.5432L12.544 1.12669L1.12746 4.9322C1.12687 4.93243 1.12631 4.9326 1.1258 4.93277C1.12521 4.93563 1.12479 4.93946 1.12493 4.94384C1.1251 4.94924 1.12605 4.95397 1.12718 4.95734C1.12734 4.95779 1.1275 4.95824 1.12764 4.95863C1.12881 4.95908 1.13034 4.95959 1.13227 4.9601C2.10793 5.22829 3.31451 5.5764 4.34533 5.92372C4.85995 6.0971 5.33994 6.27335 5.72904 6.44217C5.92346 6.52653 6.10274 6.61224 6.25576 6.69822C6.40153 6.7801 6.55461 6.87925 6.67299 6.99769C6.79142 7.11612 6.89057 7.26914 6.97251 7.41497C7.05844 7.56793 7.1442 7.74722 7.22856 7.94163C7.39732 8.33074 7.57357 8.81078 7.74695 9.32535C8.09427 10.3562 8.44244 11.5627 8.71058 12.5384C8.71114 12.5404 8.71165 12.5419 8.71204 12.5431C8.71243 12.5432 8.71288 12.5434 8.71333 12.5435C8.71676 12.5447 8.72143 12.5456 8.72683 12.5458C8.73122 12.5459 8.73504 12.5455 8.73791 12.5449C8.73808 12.5444 8.7383 12.5438 8.73847 12.5432Z"
        fill="white"
      />
    </svg>
  );
};

export default TelegramIcon;
