import React, { useContext } from "react";
import { useState } from "react";
import Balance from "../components/Balance";
import FriendsList from "../components/FriendsList";
import Navbar from "../components/Navbar";
import { userContext } from "../context/userContext";
import BellIcon from "../icons/BellIcon";
import CopyIcon from "../icons/CopyIcon";
import FriendsIcon from "../icons/FriendsIcon";
import MoreIcon from "../icons/MoreIcon";
import ProfileIcon from "../icons/ProfileIcon";
import Settingicon from "../icons/Settingicon";
import { TiTickOutline } from "react-icons/ti";

const FriendsPage = ({ coin, id }) => {
  const { data, setData } = useContext(userContext);
  const [copy, setCopy] = useState(false);
  const friends = data?.invitedPepole;
  const textToCopy = `https://t.me/Naradgame_bot?start=${data?.user?.ref}`;
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {
      setCopy(true);
    });
  };
  return (
    <div className="flex flex-col items-center w-full mt-[20px] px-[30px]">
      <div className={`flex justify-between items-center w-full `}>
        <div className="flex justify-center items-center gap-1">
          <ProfileIcon />
          <p className="text-[12px] font-[600]">{data?.user?.username}</p>
        </div>
        <div className="flex items-center"></div>
      </div>
      <Balance coin={coin} />
      <h2 className="text-[32px] font-[700] text-[#F79841] mt-[40px]">
        Invite friends
      </h2>
      <p className="text-[24px] font-[600]">You and your friends!</p>
      <div className="w-full h-[200px] overflow-y-auto">
        <span className="flex mt-2 justify-start items-center w-full bg-[#000] gap-2 px-2 shadow-md shadow-[#000000]/30 rounded-lg py-4 text-[16px] font-[600] border border-white/60">
          <FriendsIcon color={"#FFFFFF"} />
          <span>
            <p>Invite friends!</p>
            <span className="flex justify-start items-center gap-1 ">
              <p className="text-[#FFA100]">500 NARAD</p>
              <p className="text-[13px] font-[400] mt-1">
                for you and your frined
              </p>
            </span>
          </span>
        </span>
        <FriendsList friends={friends} />
      </div>
      <div className="w-full grid-cols-5 grid gap-1 mt-1">
        <span className=" col-span-4 bg-[#A87621] rounded-lg text-[20px] font-[700] text-center py-3">
          Invite friends
        </span>
        <span
          onClick={handleCopy}
          className="bg-[#A87621] rounded-lg flex justify-center items-center"
        >
          {copy ? <TiTickOutline size={30} /> : <CopyIcon />}
        </span>
      </div>
      <Navbar />
    </div>
  );
};

export default FriendsPage;
