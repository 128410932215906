import React from "react";

const EnergyIcon = () => {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5553 11.949L15.5426 10.5324C15.1333 10.4167 14.9012 9.98645 15.0309 9.58384L17.4212 2.16351C17.7807 1.69513 17.7693 1.02362 17.3613 0.56416C16.8973 0.0423184 16.097 -0.00809713 15.5735 0.45209L0.644592 13.5818C0.121193 14.0421 0.0729748 14.8387 0.536822 15.361C0.701351 15.5467 0.917206 15.6801 1.15729 15.7444C1.20101 15.7561 1.24537 15.7645 1.28991 15.7715C1.33221 15.7878 1.37553 15.8023 1.42034 15.8144L5.17447 16.8203C5.63036 16.9424 5.86247 17.4476 5.65657 17.8696L1.81961 25.734C1.39777 26.1756 1.34953 26.8694 1.73565 27.3689C1.90509 27.5888 2.14266 27.747 2.41138 27.8188C2.77921 27.9173 3.18704 27.8505 3.5118 27.602L20.9164 14.2894C21.0769 14.1667 21.2051 14.0073 21.2903 13.8246C21.3503 13.7275 21.3989 13.6213 21.4314 13.5061C21.6209 12.8364 21.2285 12.1393 20.5553 11.949Z"
        fill="url(#paint0_linear_3_1353)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3_1353"
          x1="14.659"
          y1="-0.376884"
          x2="14.659"
          y2="21.0731"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#F7C243" />
          <stop offset="1" stop-color="#FD902B" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default EnergyIcon;
